<template>
  <div>
    <b-overlay :show="isFetchingAgent">
      <b-card>
        <AgentProfileForm :agent-id="agentId" />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AgentForms',
  components: {
    AgentProfileForm: () => import('./components/AgentProfileForm.vue'),
  },
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isFetchingAgent: (state) => state.agent.isFetchingAgent,
    }),
  },
  async created() {
    if (this.agentId) {
      await this.fetchAgent(this.agentId)
    }
  },
  methods: {
    ...mapActions(['fetchAgent']),
    async onSubmitResetPassword() {
      this.$refs.agentResetPassword.onClose()
    },
  },
}
</script>
